import { videoPlayer } from 'vue-video-player';

export default {
  name: 'Player',
  props: ['poster', 'video'],
  components: {
    videoPlayer,
  },
  data() {
    return {};
  },
  computed: {
    playerOptions() {
      return {
        muted: true,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        fluid: true,
        sources: [{
          type: 'video/mp4',
          src: this.video,
        }],
        poster: this.poster,
      };
    },
  },
};
