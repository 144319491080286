import Player from '../Player/Player.vue';
import industryBg from '../../assets/industry_new.jpg';
import industrialSafetyPromo from '../../assets/industrial_safety_promo.mp4';

export default {
  name: 'DemoSafety',
  components: {
    Player,
  },
  data() {
    return {
      industryBg,
      industrialSafetyPromo,
    };
  },
};
