import Player from '../Player/Player.vue';
import foam from '../../assets/foam.jpg';
import flotationPromo from '../../assets/flotation_promo.mp4';

export default {
  name: 'DemoAutomation',
  components: {
    Player,
  },
  data() {
    return {
      foam,
      flotationPromo,
    };
  },
};
