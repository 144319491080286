import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueScrollactive from 'vue-scrollactive';
import InlineSvg from 'vue-inline-svg';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import i18n from './i18n';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueScrollactive);
Vue.component('inline-svg', InlineSvg);

new Vue({
  i18n,
  render: (h) => h(App),
}).$mount('#app');
