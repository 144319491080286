import Player from '../Player/Player.vue';
import Advantages from '../Advantages/Advantages.vue';
import Contacts from '../Contacts/Contacts.vue';
import DemoAnalogs from '../DemoAnalogs/DemoAnalogs.vue';
import DemoProcessControl from '../DemoProcessControl/DemoProcessControl.vue';
import DemoSafety from '../DemoSafety/DemoSafety.vue';
import DemoAutomation from '../DemoAutomation/DemoAutomation.vue';
import Scheme from '../Scheme/Scheme.vue';

export default {
  name: 'Index',
  components: {
    Player,
    Advantages,
    Contacts,
    DemoAnalogs,
    DemoProcessControl,
    DemoSafety,
    DemoAutomation,
    Scheme,
  },
  data() {
    return {
      visibleNavbar: false,
      hiddenBody: false,
      langs: ['en', 'ru'],
    };
  },
  methods: {
    onScroll() {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      this.visibleNavbar = currentScrollPosition > 100;
    },
    setBodyOverflow() {
      this.hiddenBody = !this.hiddenBody;
      if (this.hiddenBody) {
        document.querySelector('body').style.overflow = 'hidden';
      } else {
        document.querySelector('body').style.overflow = 'visible';
      }
    },
    closeMobileMenu() {
      if (this.hiddenBody) {
        this.$root.$emit('bv::toggle::collapse', 'nav-collapse');
        this.hiddenBody = false;
        document.querySelector('body').style.overflow = 'visible';
      }
    },
    setLocale() {
      this.closeMobileMenu();
      if (this.$i18n.locale === 'en') {
        this.$i18n.locale = 'ru';
        localStorage.setItem('locale', this.$i18n.locale);
      } else if (this.$i18n.locale === 'ru') {
        this.$i18n.locale = 'en';
        localStorage.setItem('locale', this.$i18n.locale);
      }
    },
  },
  created() {
    const locale = localStorage.getItem('locale');
    if (!locale) {
      localStorage.setItem('locale', this.$i18n.locale);
    } else {
      this.$i18n.locale = locale;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },
};
