import Player from '../Player/Player.vue';
import analogsBg from '../../assets/analogs_cover.jpg';
import analogsPromo from '../../assets/analogs_demo.mp4';

export default {
  name: 'DemoAnalolgs',
  components: {
    Player,
  },
  data() {
    return {
      analogsBg,
      analogsPromo,
    };
  },
};
