<template>
  <div id="app">
    <Index/>
  </div>
</template>

<script>
import Index from './components/Index/Index.vue';

export default {
  name: 'App',
  components: {
    Index,
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,500;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

#app {
  height: 100%;
  width: 100%;
  font-family: 'Raleway', sans-serif;
}
</style>
