import { render, staticRenderFns } from "./DemoAutomation.html?vue&type=template&id=fb97d82e&"
import script from "./DemoAutomation.js?vue&type=script&lang=js&"
export * from "./DemoAutomation.js?vue&type=script&lang=js&"
import style0 from "./DemoAutomation.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports