import Player from '../Player/Player.vue';
import processBg from '../../assets/control.jpg';
import processControlPromo from '../../assets/control_promo.mp4';

export default {
  name: 'DemoProcessControl',
  components: {
    Player,
  },
  data() {
    return {
      processBg,
      processControlPromo,
    };
  },
};
